//
// Plugins
//

// Import Dependencies
@import 'root';
@import 'prismjs';
@import 'fslightbox';
@import 'select2';
@import 'formvalidation';
@import 'daterangepicker';
@import 'flatpickr';
@import '~flatpickr/dist/flatpickr.css';
@import 'tagify';
@import 'bootstrap-maxlength';
@import 'ckeditor';
@import 'datatables';
@import 'dropzone';
@import 'gmaps';
@import 'nouislider';
@import 'quill';
@import 'recaptcha';
@import 'sweetalert2';
@import 'tinymce';
@import 'toastr';
@import 'draggable';
@import 'apexcharts';
@import 'leaflet';
@import 'tiny-slider';
@import 'fullcalendar';
@import 'jkanban';
@import 'jstree';
@import 'vis-timeline';
@import 'tempus-dominus';
@import 'keenicons';
